import React, { useEffect, useState } from "react";
import {
  FooterContainer,
  CopyrightText,
  LinkContainer,
  DisclaimerText,
  CompanyAddress,
} from "./Footer.styles";
import { AppRoutes } from "../../app/routes";
import { flixProviderSignal, hasNextPageSignal, useFlix } from "../flix/useFlix";
import { useLocation } from "react-router-dom";

const Footer: React.FC = () => {
  const location = useLocation();
  const { initFlixData } = useFlix();
  const provider = flixProviderSignal.value
  const [visible, setVisible] = useState(false);
  const [p, setP] = useState(provider)

  useEffect(() => {
    if (location.pathname === AppRoutes.JOIN_CLEARPLAY) {
      return setVisible(true);
    }
    if (!initFlixData?.pages.length) {
      setVisible(false);
    }
    if (!hasNextPageSignal.value) {
      setVisible(true);
    }
  }, [hasNextPageSignal.value, provider, initFlixData?.pages, location]);

  useEffect(() => {
    setP(provider)
  },[provider])

  const hasFlixData = initFlixData?.pages?.length;

  return (location.pathname === AppRoutes.HOME ||
    location.pathname === AppRoutes.HOME_EXPLORE ||
    location.pathname === AppRoutes.JOIN_CLEARPLAY) &&
    hasFlixData ? (
    <FooterContainer className={`${visible ? "visible" : ""}`}>
      <LinkContainer>
        <a
          href="https://join.clearplay.com/about/"
          target="_blank"
          rel="noreferrer"
        >
          About us
        </a>
        <a
          href="https://help.clearplay.com"
          target="_blank"
          rel="noreferrer"
        >
          FAQ
        </a>
        <a
          href="https://try.clearplay.com/privacy-notice/"
          target="_blank"
          rel="noreferrer"
        >
          Privacy Policy
        </a>
        <a
          href="https://join.clearplay.com/terms/"
          target="_blank"
          rel="noreferrer"
        >
          Terms of use
        </a>
        <a
          href="https://join.clearplay.com/help/"
          target="_blank"
          rel="noreferrer"
        >
          Contact us
        </a>
      </LinkContainer>
      <DisclaimerText>
        All trademarks, logos and brand names are the property of their
        respective owners. All company, product and service names used in this
        website are for identification purposes only. Use of these names,
        trademarks and brands does not imply endorsement.
      </DisclaimerText>
      <CompanyAddress>
        Clearplay Inc. 404 East 4500 South, Suite B33, Salt Lake City, UT 84107
      </CompanyAddress>
      <CopyrightText>© 2024 Clearplay Inc. | &nbsp;<span onClick={() => {
        window.open("https://try.clearplay.com/patents/", "_blank")}}>Clearplay Patents</span></CopyrightText>
     
    </FooterContainer>
  ) : (
    <></>
  );
};

export default Footer;
