import { apiRequest, API_TYPE, HTTP_METHOD } from "../../app/api";
import { ConstructQueryUser } from "./userModel";
import { AxiosError } from "axios";
import { SubscriptionOptions, User } from "./userTypes";
import { FilteringMessageConstructor } from "../adminFeatures/tables/tableModels";


export const forgotPassword = async (body: {email: string, captchaToken: string}) => {
  const res = await apiRequest(
    HTTP_METHOD.POST,
    API_TYPE.FILTER_API,
    `/accounts/forgot`,
    body
  );
  return res;
};

export const getMe = async (): Promise<User> => {
  try {
    const user = await apiRequest(
      HTTP_METHOD.GET,
      API_TYPE.FILTER_API,
      `/accounts/details`
    );
    return ConstructQueryUser(user);
  } catch (error) {
    const axiosError = error as AxiosError;
    throw axiosError;
  }
};

interface PatchmMeBody {
  phone?: string
  email?: string
}

export const patchMe = async (body: PatchmMeBody): Promise<any> => {
  try {
    const data = await apiRequest(
      HTTP_METHOD.PATCH,
      API_TYPE.FILTER_API,
      `/accounts/details`,
      body
    );
    return data
  } catch (error) {
    const axiosError = error as AxiosError;
    throw axiosError;
  }
};
export const login = async (body: {
  email: string;
  password: string;
}): Promise<any> => {
  try {
    const token = await apiRequest(
      HTTP_METHOD.POST,
      API_TYPE.FILTER_API,
      `/accounts/login`,
      body
    );
    return token;
  } catch (error) {
    const axiosError = error as AxiosError;
    throw axiosError;
  }
};
export const accountLookup = async (email: string): Promise<string | null> => {
  try {
    const data = await apiRequest(
      HTTP_METHOD.POST,
      API_TYPE.FILTER_API,
      `/accounts/type_lookup`,
      { email: email }
    );
    return data.account_type;
  } catch (error) {
    const axiosError = error as AxiosError;
    throw axiosError;
  }
};
export const signup = async (body: {
  email: string;
  captchaToken: string;
  referral: string;
  password: string;
}): Promise<any> => {
  try {
    const data = await apiRequest(
      HTTP_METHOD.POST,
      API_TYPE.FILTER_API,
      `/accounts/signup`,
      body
    );
    return data;
  } catch (error) {
    const axiosError = error as AxiosError;
    throw axiosError;
  }
};
export const addCustomer = async (): Promise<any> => {
  try {
    const data = await apiRequest(
      HTTP_METHOD.POST,
      API_TYPE.FILTER_API,
      `/accounts/customers`
    );
    return data;
  } catch (error) {
    const axiosError = error as AxiosError;
    throw axiosError;
  }
};
export const addCC = async (body: {
  card_token: string;
  firstName: string;
  lastName: string;
  phone: string;
  state?: string;
  zipCode: string;
}): Promise<any> => {
  try {
    const data = await apiRequest(
      HTTP_METHOD.POST,
      API_TYPE.FILTER_API,
      `/accounts/subscriptions/payments`, body
    );
    return data;
  } catch (error) {
    const axiosError = error as AxiosError;
    throw axiosError;
  }
};

export interface subscriptionData {
item_id: string
setup_intent?: string
referral_source?: string
promo_code?: string
}


export const addOrCancelSubscription = async (body: subscriptionData): Promise<any> => {
  try {
    const data = await apiRequest(
      HTTP_METHOD.POST,
      API_TYPE.FILTER_API,
      `/accounts/subscriptions`, body
    );
    return data;
  } catch (error) {
    const axiosError = error as AxiosError;
    throw axiosError;
  }
};
export const addNewCard = async (body: {
setup_intent?: string
}): Promise<any> => {
  try {
    const data = await apiRequest(
      HTTP_METHOD.POST,
      API_TYPE.FILTER_API,
      `/accounts/payments/confirm`, body
    );
    return data;
  } catch (error) {
    const axiosError = error as AxiosError;
    throw axiosError;
  }
};
// export const getPaymentIntent = async (): Promise<any> => {
//   try {
//     const data = await apiRequest(
//       HTTP_METHOD.POST,
//       API_TYPE.FILTER_API,
//       `/accounts/payments/setup`
//     );
//     return data;
//   } catch (error) {
//     const axiosError = error as AxiosError;
//     throw axiosError;
//   }
// };

// export const fetchSubscriptionOptions = async (body: {
//   promo_code?: string
//   }): Promise<SubscriptionOptions[]> => {
//     try {
//       const data = await apiRequest(
//         HTTP_METHOD.POST,
//         API_TYPE.FILTER_API,
//         `/accounts/subscriptions/options`,
//         body
//       );
//       return data;
//     } catch (error) {
//       const axiosError = error as AxiosError;
//       throw axiosError;
//     }
//   };

  export const patchPassword = async (body: {password: string}): Promise<any> => {
    try {
      const data = await apiRequest(
        HTTP_METHOD.PATCH,
        API_TYPE.FILTER_API,
        `/accounts/passwords`,
        body
      );
      return data
    } catch (error) {
      const axiosError = error as AxiosError;
      throw axiosError;
    }
  };

  export const fetchFilteringStatus = async () => {
    try {
      const data = await apiRequest(
        HTTP_METHOD.GET,
        API_TYPE.FILTER_API,
        `/platforms/disabled`,
        {}
      );
      return FilteringMessageConstructor(data);
    } catch (error) {
      const axiosError = error as AxiosError;
      throw axiosError;
    }
  };

  export const sendReferral = async(opts: {
    referral: string
   }) => {
    try {
      const res = await apiRequest(
        HTTP_METHOD.POST,
        API_TYPE.CLEARPLAY_API,
        `?apiKey=wkxS49QILyzvHXl9Q6zGXbaGhQVoVqqc&function=saveContactInfo&referral=${
          opts.referral
        }`
      );
      return res;
    } catch (error) {
      const axiosError = error as AxiosError;
      throw axiosError;
    }
  }
  
  export const getStripeAccountUrl = async () => {
    try {
      const data = await apiRequest(
        HTTP_METHOD.POST,
        API_TYPE.FILTER_API,
        `/accounts/billings/portals`,
        {return_url: `${process.env.REACT_APP_REDIRECT}/my-account`}
      );
      return data.url;
    } catch (error) {
      const axiosError = error as AxiosError;
      throw axiosError;
    }
  };
  export const getStripeSignupUrl = async (referral?: string) => {
    try {
      const data = await apiRequest(
        HTTP_METHOD.POST,
        API_TYPE.FILTER_API,
        `/accounts/billings/checkouts`,
        {return_url: `${process.env.REACT_APP_REDIRECT}/welcome`,
        referral_source: referral}
      );
      return data.url;
    } catch (error) {
      const axiosError = error as AxiosError;
      throw axiosError;
    }
  };
  export const restartSubscription = async (subscriptionId: string) => {
    try {
      const data = await apiRequest(
        HTTP_METHOD.PATCH,
        API_TYPE.FILTER_API,
        `/accounts/subscriptions/restart`,
        {subscription_id: subscriptionId}
      );
      return data;
    } catch (error) {
      const axiosError = error as AxiosError;
      throw axiosError;
    }
  };
