import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import {
  MovieAssetUpdateFormEnum,
  ShowAssetUpdateFormEnum,
} from "../features/adminFeatures/assets/assetTypes";
import {
  AdminTableSelection,
  Platform,
} from "../features/adminFeatures/tables/tableTypes";
import { ApiTypes, contentUrl, providerToIdMap } from "../app/app.types";
import algoliaImage from "../assets/images/admin-images/algolia.webp";
import auth0Image from "../assets/images/admin-images/auth0.png";
import awsImage from "../assets/images/admin-images/aws.avif";
import freshpingImage from "../assets/images/admin-images/freshping.webp";
import mailchimpImage from "../assets/images/admin-images/mailchimp.png";
import networkSolutionsImage from "../assets/images/admin-images/network-solutions.webp";
import stripeImage from "../assets/images/admin-images/stripe.png";
import sumoLogicImage from "../assets/images/admin-images/sumo-logic.webp";
import unbounceImage from "../assets/images/admin-images/unbounce.png";
import zapierImage from "../assets/images/admin-images/zapier.webp";
import adrollImage from "../assets/images/admin-images/adrollImage.png";
import churnkeyImage from "../assets/images/admin-images/churnkeyImage.png";
import googleAnalyticsImage from "../assets/images/admin-images/googleAnalyticsImage.png";
import googleSearchConsoleImage from "../assets/images/admin-images/googleSearchConsoleImage.jpeg";
import mandrillImage from "../assets/images/admin-images/mandrillImage.jpeg";
import bitwardenImage from "../assets/images/admin-images/bitwardenImage.webp";
import digicertImage from "../assets/images/admin-images/digicertImage.png";
import recaptchaImage from "../assets/images/admin-images/recaptchaImage.png";
import amazonVideoImage from "../assets/images/admin-images/amazonVideoImage.webp";
import appleTvImage from "../assets/images/admin-images/appleTvImage.png";
import disneyPlusImage from "../assets/images/admin-images/disneyPlusImage.jpeg";
import maxImage from "../assets/images/admin-images/maxImage.jpeg";
import netflixImage from "../assets/images/admin-images/netflixImage.webp";
import azureDevOpsImage from "../assets/images/admin-images/azureDevOpsImage.png";
import chromeWebStoreDevImage from "../assets/images/admin-images/chromeWebStoreDevImage.avif";
import jwtIoDebuggerImage from "../assets/images/admin-images/jwtIoDebuggerImage.png";
import pageSpeedInsightsImage from "../assets/images/admin-images/pageSpeedInsightsImage.png";
import slackImage from "../assets/images/admin-images/slackImage.jpeg";
import trelloClearplayImage from "../assets/images/admin-images/trelloClearplayImage.png";
import googleTagManagerImage from "../assets/images/admin-images/googleTagManagerImage.png";
import { userMessageSignal } from "../features/user/userSignals";
import { fetchMovieDatabaseAsset } from "../features/adminFeatures/assets/asset.api";


dayjs.extend(utc);

export const isModifierKey = (event: any) => {
  const key = event.keyCode;
  return (
    event.shiftKey === true ||
    key === 35 ||
    key === 36 || // Allow Shift, Home, End
    key === 8 ||
    key === 9 ||
    key === 13 ||
    key === 46 || // Allow Backspace, Tab, Enter, Delete
    (key > 36 && key < 41) || // Allow left, up, right, down
    // Allow Ctrl/Command + A,C,V,X,Z
    ((event.ctrlKey === true || event.metaKey === true) &&
      (key === 65 || key === 67 || key === 86 || key === 88 || key === 90))
  );
};

export const formatToDuration = (event: any) => {
  if (isModifierKey(event)) {
    return;
  }

  const input = event.target.value.replace(/\D/g, "").substring(0, 10); // First ten digits of input only
  const hours = input.substring(0, 2);
  const minutes = input.substring(2, 4);
  const seconds = input.substring(4, 6);
  const mills = input.substring(6, 9);

  if (input.length > 5) {
    event.target.value = `${hours}:${minutes}:${seconds}.${mills}`;
  } else if (input.length > 4) {
    event.target.value = `${hours}:${minutes}:${seconds}`;
  } else if (input.length > 2) {
    event.target.value = `${hours}:${minutes}`;
  } else if (input.length > 0) {
    event.target.value = `${hours}`;
  }
};

export const formatToSemver = (event: any) => {
  if (isModifierKey(event)) {
    return;
  }

  const input = event.target.value;

  let val = input; //remove all dashes (-)
  if (val.length < 5) {
    let finalVal = val.replace(/(\d)(?=(\d{1})+(?!\d+$))/g, "$1.");
    if (finalVal === 0) {
      finalVal = "0.0.0";
    }
    return finalVal;
  }
};

const isNumericInput = (event: any) => {
  const key = event.keyCode;
  return (
    (key >= 48 && key <= 57) || // Allow number line
    (key >= 96 && key <= 105) // Allow number pad
  );
};
export const enforceFormat = (event: any) => {
  // Input must be of a valid number format or a modifier key, and not longer than ten digits
  if (!isNumericInput(event) && !isModifierKey(event)) {
    event.preventDefault();
  }
};

export const isValidStart = (value: string) => {
  if (value.length < 4) {
    return false;
  } else return true;
};

// export const getImageSize = async (url: string) => {
//     var xhr = new XMLHttpRequest();
// xhr.open("GET", url, true);
// xhr.responseType = "arraybuffer";
// xhr.onreadystatechange = function() {
//   if(this.readyState == this.DONE) {
//       return byteConverter(this.response.length)
//   }
// };
// xhr.send(null);
// }

export const getFilmUrl = (
  platform: number,
  row: any,
  selectedTable: string
): string => {
  const type =
    selectedTable === AdminTableSelection.MOVIE_ASSET
      ? 1
      : selectedTable === AdminTableSelection.SHOW_ASSET
      ? 2
      : row.asset_type_id;

  let identifier =
    selectedTable === AdminTableSelection.MOVIE_ASSET
      ? row.video_identifier
      : selectedTable === AdminTableSelection.SHOW_ASSET
      ? row.episode_partner_identifier
      : row.partner_identifier;

  if (identifier.includes("@")) {
    identifier = identifier.split("@")[0];
  } else if (identifier.includes("&")) {
    identifier = identifier.split("&")[0];
  } 

  switch (providerToIdMap[platform]) {
    case ApiTypes.DISNEY: {
      return `${contentUrl[providerToIdMap[platform]]}${
        type === 2 ? "series" : "movies"
      }/${row.platform_title}/${identifier}`;
    }
    case ApiTypes.APPLE: {
      return `${contentUrl[providerToIdMap[platform]]}${
        type === 2 ? "show" : "movie"
      }/${identifier}`;
    }
    case ApiTypes.MAX: {
      return `${contentUrl[providerToIdMap[platform]]}${
        type === 2 ? "show" : "movie"
      }/${identifier}`;
    }
    default: {
      return `${contentUrl[providerToIdMap[platform]]}${identifier}`;
    }
  }
};

export const byteConverter = (x: any) => {
  const units = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  let l = 0,
    n = parseInt(x, 10) || 0;
  while (n >= 1024 && ++l) {
    n = n / 1024;
  }
  return n.toFixed(n < 10 && l > 0 ? 1 : 0) + " " + units[l];
};

export const getTMDBValues = (value: string, key: string) => {

  if (
    [
      ShowAssetUpdateFormEnum.SERIES_IMDB_ID,
      ShowAssetUpdateFormEnum.EPISODE_IMDB_ID,
      MovieAssetUpdateFormEnum.IMDB_ID,
    ].includes(key as ShowAssetUpdateFormEnum | MovieAssetUpdateFormEnum)
  ) {
    fetchMovieDatabaseAsset(value)
  } else return;
};

export const goToIMDB = (name: string, date?: string) => {
  const year = date ? dayjs(date).format("YYYY") : "";
  const regex = /\(.*$/;
  const semParens = name.replace(regex, "");
  const search = `${semParens} ${year && `,${year}`}`;
  window.open(`https://www.imdb.com/find?q=${search}&ref_=nv_sr_sm`, "_blank");
};

export const useCopyToClipboard = () => {
  const copyToClipBoard = (value: string, key: string) => {
    navigator.clipboard.writeText(value);
    userMessageSignal.value = {
      message: `${key}: ${value} copied to clipboard`,
      color: "green",
    }
  };
  return { copyToClipBoard };
};

export const utcDate = (date: string) => {
  return dayjs.utc(date).local().format("MM/DD/YYYY");
};

export const filteringDownProvider = (
  filteringDown: Platform[] | undefined,
  provider: number
) => {
  if (!filteringDown) return;
  const platform = filteringDown.find((platform: { platform_id: number }) => {
    return platform.platform_id === provider;
  });
  return platform || null
};
export const filteringDownMessage = (
  filteringDown: any[] | undefined,
  provider: number
) => {
  if (!filteringDown) return;
  return filteringDown.map((platform) => {
    let message = "";
    if (platform.platform_id === provider) {
      message = platform.message;
    }
    return message;
  });
};

export const getCleanedFormState = (
  contentType: "movie" | "show",
  formState: any
) => {
  const cleanedFormState = { ...formState };

  Object.keys(cleanedFormState).forEach((key) => {
    if (cleanedFormState[key] === null || cleanedFormState[key] === "") {
      delete cleanedFormState[key];
    }
  });

  // Convert date to the desired format
  if (contentType === "movie" && cleanedFormState.release_date) {
    cleanedFormState.release_date = dayjs(cleanedFormState.release_date).format("YYYY-MM-DD");
  }

  return cleanedFormState;
};


// admin images

export const serviceImages: any = {
  Algolia: algoliaImage,
  Auth0: auth0Image,
  AWS: awsImage,
  Freshping: freshpingImage,
  Mailchimp: mailchimpImage,
  "Network Solutions": networkSolutionsImage,
  Stripe: stripeImage,
  "Sumo Logic": sumoLogicImage,
  Unbounce: unbounceImage,
  Zapier: zapierImage,
  AdRoll: adrollImage,
  "Google Analytics": googleAnalyticsImage,
  "Google Search Console": googleSearchConsoleImage,
  "Google Tag Manager": googleTagManagerImage,
  //     'Clearplay asp.netstorefront Admin': clearplayAdminImage,
  //     'Clearplay DVD Signin': clearplayDvdImage,
  //     'Clearplay Extension - Chrome Web Store': clearplayExtensionImage,
  //     'Clearplay Extension (Dev) - Chrome Web Store': clearplayExtensionDevImage,
  //     'Clearplay Web - Dev': clearplayWebDevImage,
  //     'Clearplay Web - Stage': clearplayWebStageImage,
  //     'Clearplay Web': clearplayWebImage,
  //     'Streamer - Dev': streamerDevImage,
  //     'Streamer': streamerImage,
  Churnkey: churnkeyImage,
  Mandrill: mandrillImage,
  Bitwarden: bitwardenImage,
  Digicert: digicertImage,
  reCAPTCHA: recaptchaImage,
  "Amazon Video": amazonVideoImage,
  "Apple TV+": appleTvImage,
  "Disney+": disneyPlusImage,
  Max: maxImage,
  Netflix: netflixImage,
  "Azure DevOps": azureDevOpsImage,
  "Chrome Web Store Developer Dashboard": chromeWebStoreDevImage,
  "JWT.IO Debugger": jwtIoDebuggerImage,
  "PageSpeed Insights - web.clearplay.com": pageSpeedInsightsImage,
  Slack: slackImage,
  "Trello - Clearplay Board": trelloClearplayImage,
};

export const deepClone = (obj: any) => {
  return JSON.parse(JSON.stringify(obj));
};