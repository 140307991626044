import { Signal } from "@preact/signals-react";

export interface AssetState {
  assetDetail: AssetDetail[] | undefined;
  loading: boolean;
  currentTable: string;
  assetTMDB: TMDBAsset | undefined;
  error: boolean;
  shelfList: any;
  releasedAssets: ReleasedAsset[];
  shelfSortTypes: { asset_list_sort_id: number; name: string }[];
}

export interface AssetDetail {
  clearplay?: any;
  comment?: string | null;
  date: string;
  score: number;
  userId: string;
  email: string;
  count?: number;
  partner_identifier: string;
  platform_id: string;
}

export interface MovieScoreAsset {
  clearplay?: any;
  comment?: string | null;
  date: string;
  score: number;
  userId: string;
  email: string;
  count?: number;
}

export interface TMDBAsset {
  movie_results: Movieresult[];
  person_results: any[];
  tv_results: any[];
  tv_episode_results: Tvepisoderesult[];
  tv_season_results: any[];
}

interface Tvepisoderesult {
  air_date: string;
  episode_number: number;
  id: number;
  name: string;
  overview: string;
  production_code: string;
  season_number: number;
  show_id: number;
  still_path?: any;
  vote_average: number;
  vote_count: number;
}

interface Movieresult {
  original_title: string;
  poster_path: string;
  video: boolean;
  vote_average: number;
  overview: string;
  release_date: string;
  vote_count: number;
  adult: boolean;
  backdrop_path: string;
  id: number;
  genre_ids: number[];
  title: string;
  original_language: string;
  popularity: number;
}

export type AssetDrawerFormFields = MovieAssetUpdateForm | ShowAssetUpdateForm;

//used in AdminDrawers
export interface MovieAssetUpdateForm {
  display_name: string;
  imdb_id: string;
  release_date: string;
  credit_start_time: string;
  searchable: boolean;
  filter_released: boolean;
  duration: string;
  classification_version_id?: any;
}

export enum MovieAssetUpdateFormEnum {
  DISPLAY_NAME = 'display_name',
  IMDB_ID = 'imdb_id',
  RELEASE_DATE = 'release_date',
  FILTER_RELEASE_DATE = 'filter_release_date',
  CREDIT_START_TIME = 'credit_start_time',
  SEARCHABLE = 'searchable',
  FILTER_RELEASED = 'filter_released',
  DURATION = 'duration',
  CLASSIFICATION = 'classification_version_id',
}

export type MovieAssetUpdateFormKey = keyof MovieAssetUpdateForm;

export interface ShowAssetUpdateForm {
  episode_display_name: string | null;
  episode_name: string | null;
  series_imdb_id: string | null;
  series_display_name: string | null;
  episode_credit_start_time: string | null;
  episode_duration: string | null;
  episode_imdb_id: string | null;
  series_searchable: boolean;
  episode_classification_version_id: number | null;
  series_platform_title: string | null;
  series_video_id: string | null;
}

export enum ShowAssetUpdateFormEnum {
  EPISODE_DISPLAY_NAME = 'episode_display_name',
  SERIES_IMDB_ID = 'series_imdb_id',
  SERIES_DISPLAY_NAME = 'series_display_name',
  // FILTER_RELEASE_DATE = 'episode_filter_release_date',
  EPISODE_CREDIT_START_TIME = 'episode_credit_start_time',
  EPISODE_DURATION = 'episode_duration',
  EPISODE_IMDB_ID = 'episode_imdb_id',
  SEARCHABLE = 'series_searchable',
  SERIES_CLASSIFICATION = 'episode_classification_version_id',
  SERIES_PLATFORM_TITLE = 'series_platform_title',
  SERIES_VIDEO_ID = 'series_video_id',
  EPISODE_PLATFORM_ID = 'episode_platform_id',
}

export type ShowAssetUpdateFormKey = keyof ShowAssetUpdateForm;

export enum ShelfAssetUpdateFormEnum {
  ASSET_LIST_TYPE_ID = 'asset_list_type_id',
  NAME = 'name',
  DESCRIPTION = 'description',
  IS_ACTIVE = 'isActive',
}

export interface ShelfAsset {
  asset_id: number;
  credit_start_time: string;
  asset_list_id: number;
  display_name: string;
  duration: string;
  asset_identifier_id: number;
  imdb_id: string;
  img_URL: string;
  name: string;
  platform_id: number;
  release_date: string;
  searchable: boolean;
  type_id: number;
  filter_id: number;
  filter_release_date: string;
  is_active: boolean;
  display_order?: any;
  score: number;
  customer_votes: number;
}

export interface ShelvesTable {
  asset_list_type_id: number;
  name: string;
  description: string;
  isActive: boolean;
  priority: number;
  asset_list_sort_id: number;
  assets: ShelfAsset[];
}

export type ShelvesTableFormKey = keyof ShelvesTable;

export type AssetDrawerVals = EpisodeOpject | MovieObject;

export interface EpisodeOpject {
  episode_asset_id: number;
  episode_asset_identifier_id: number;
  episode_credit_start_time?: any;
  episode_display_name: string;
  episode_duration: string;
  episode_filter_release_date: string;
  episode_filter_released: boolean;
  episode_imdb_id: string;
  episode_name: string;
  episode_number: number;
  episode_partner_identifier: string;
  episode_platform_id: number;
  season_asset_id: number;
  season_number: number;
  series_asset_id: number;
  series_display_name: string;
  series_imdb_id: string;
  series_img_URL?: any;
  series_name: string;
  series_searchable: boolean;
  episode_classification_version_id: number;
  episode_filter_id: number;
}

interface MovieObject {
  asset_id: number;
  credit_start_time: string;
  display_name: string;
  duration: string;
  asset_identifier_id: number;
  imdb_id: string;
  img_URL: string;
  name: string;
  platform_id: number;
  release_date: string;
  searchable: boolean;
  type_id: number;
  filter_id: number;
  filter_release_date: string;
  classification_version_id: number;
  filter_released: boolean;
  video_identifier: string;
}

export interface ReleasedAsset {
  asset_id: number;
  display_name: string;
  asset_identifier_id: number;
  imdb_id: string;
  img_URL: string;
  name: string;
  platform_id: number;
  release_date: string;
  type_id: number;
  video_identifier: string;
  score: number;
  customer_votes: number;
}


export interface Asset {
  asset_identifier_id: number;
  platform_id: number;
  asset_id: number;
  display_name: string;
  imdb_id: string;
  img_url: string;
  asset_type_id: number;
  platform_url: string;
}

export interface AssetList {
  platform_id: number;
  asset_list_type_id: number;
  name: string;
  asset_count: number;
  assets: Asset[];
}

export interface FetchFlixResponse {
  pages: AssetList[][];
  pageParams?: number[];
}

export interface ShelfPagination {
  offset: Signal<number>;
  limit: Signal<number>;
  initialFetch: Signal<boolean>;
  isEnd: Signal<boolean>
}

export interface AssetPagination {
  [key: string]: ShelfPagination;
}
