import { atom } from 'jotai'
import { AdminTableSelection } from '../features/adminFeatures/tables/tableTypes';

export const initPreLoginUser = {
  email: '',
  password: '',
  accountType: '',
  referral: '',
  promo: ''
}
export const preLoginUser = atom(initPreLoginUser)



type FilterToggleType = {
  [key: string]: {
    isChecked: boolean;
    header: string;
    isTrueChecked?: boolean; // Optional property for dual toggles
    isFalseChecked?: boolean; // Optional property for dual toggles
  };
};

export const initAdminAppState = {
  selectedTable: AdminTableSelection.MOVIE_ASSET,
  filterToggles: {released: false, searchable: false, noPoster: false, noIMDB: false, classification: false},
  adminDrawer: {open: false, values: undefined, rowIndex: undefined},
  filterToggle: {
    released: {isChecked: false, header: ''},
    searchable:  {isChecked: false, header: ''},
    noPoster: {isChecked: false, header: ''},
    noIMDB:  {isChecked: false, header: ''},
    classification: {isChecked: false, header: ''},
  } as FilterToggleType,
  searchValue: 'Search',
  loading: false
}

export const adminAppState = atom(initAdminAppState)



