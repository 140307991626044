import { useHistory } from "react-router-dom";
import { AppRoutes } from "../../app/routes";
import { useCookies } from "react-cookie";
import { useEffect, useRef } from "react";
import {
  LocalStorage,
  getLocalStorageItem,
  setLocalStorageItem,
} from "../../utils/localStorageService";
import { useSetAtom } from "jotai";
import { preLoginUser } from "../../app/store";
import {
  getCurrentPathIndex,
  returnProviderFromIndex,
} from "../flix/flixTypes";
import { hasExtension } from "../user/userSignals";
import { flixProviderSignal } from "../flix/useFlix";
import { extensionObserverDidRun } from "./ExtensionDetector";
import { effect } from "@preact/signals-react";
interface ILinkDetector {
  children: any;
}

const LinkDetector: React.FC<ILinkDetector> = ({ children }) => {
  const history = useHistory();
  const setPreLoginCodes = useSetAtom(preLoginUser);
  const path = window.location.pathname;
  const [, setCookie] = useCookies(["extension_post_install_url"]);
  
  const didLoadRef = useRef(false);
  
  const isRedirect = path === "/redirect-link";
  const queryString = window.location.search;
  let params = new URLSearchParams(queryString);
  const url = params.get("url");
  const searchParams = new URLSearchParams(window.location.search);
  const hasQuery = searchParams.has('q'); // Check if 'q' parameter is 
  const isProviderSelector = [ "amazon",
  "/apple",
  "/disney",
  "/max",
  "/netflix",
  "/search"].includes(path) || hasQuery
  
  const handleRedirect = () => {
    if (isRedirect) {
      if (hasExtension.value) {
        if (url) {
          window.open(url, "_self");
        }
      } else {
        setCookie("extension_post_install_url", `${url}`, {
          domain: ".clearplay.com",
        });
        window.open(
          `https://join.clearplay.com/filter-movie/?redirect_url=${url}`,
          "_self"
        );
      }
    } else if (isProviderSelector) {
      const providerIndex = getCurrentPathIndex(path);
    flixProviderSignal.value = returnProviderFromIndex(providerIndex);

    if (path === '/' && params.has('q')) {
      // If it's a search query from the root, redirect to /search while preserving the query string
      history.push(`/${queryString}`);
    } else {
      // For other cases, just navigate to the appropriate path with query string
      history.push(`${path}${queryString}`);
    }
  }
    didLoadRef.current = true;
  };

  useEffect(() => {
    const redirect_uri = new URLSearchParams(window.location.search).get(
      "redirect_uri"
    );
    const promo_code = new URLSearchParams(window.location.search).get(
      "promo_code"
    );
    const referral_code = new URLSearchParams(window.location.search).get(
      "referral"
    );
    const utm_source = new URLSearchParams(window.location.search).get(
      "utm_source"
    );

    redirect_uri &&
      setLocalStorageItem(LocalStorage.REDIRECT_URL, redirect_uri);
    promo_code && setLocalStorageItem(LocalStorage.PROMO_CODE, promo_code);
    referral_code &&
      setLocalStorageItem(LocalStorage.REFERRAL_SOURCE, referral_code);
    utm_source && setLocalStorageItem(LocalStorage.UTM_SOURCE, utm_source);

    const redirect = getLocalStorageItem(LocalStorage.REDIRECT_URL);
    const promo = getLocalStorageItem(LocalStorage.PROMO_CODE);
    const referral = getLocalStorageItem(LocalStorage.REFERRAL_SOURCE);
    const utm = getLocalStorageItem(LocalStorage.UTM_SOURCE);

    setPreLoginCodes((prev) => ({
      ...prev,
      referral: referral ? referral : "",
      promo: promo ? promo : "",
      redirect: redirect ? encodeURIComponent(redirect) : "",
      utm: utm ? utm : "",
    }));
  }, []);

  effect(() => {
    if (!didLoadRef.current && extensionObserverDidRun.value) {
      handleRedirect();
    }
  });

  // this handles redirects from links in emails or share links

  return <>{children}</>;
};

export default LinkDetector;

