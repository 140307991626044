import { useMemo } from "react";
import FilterToggle from "./filter/FilterToggle";
import dayjs from "dayjs";
import HeaderTooltip from "./HeaderTooltip";
import CopyToFilter from "./CustomCellComponents/CopyToFilter";
import { AdminTableSelection } from "../../features/adminFeatures/tables/tableTypes";
import { displayProviderMap } from "../../app/app.types";
import { OpenUrl } from "./CustomCell.styles";

export const showHideButton = [
  AdminTableSelection.CUSTOMER_COMMENTS,
  AdminTableSelection.DURATION_MISMATCHES,
];
export const toggleCells = [
  "requires_membership",
  "enabled",
  "moviesActive",
  "searchable",
  "isSearchable",
  "showsActive",
  "web_shelves",
  "web_banner",
  "web_search",
  "channel_disabled",
  "channel_maintenance_mode",
];

export const numberInputCells = ["time_offset"];

export const textInputCells = [
  "maintenanceMessage",
  "channel_maintenance_message",
];
export const yesNoCells = [
  "filter_released",
  "searchable",
  "series_searchable",
  "episode_filter_released",
  "img_URL",
  "series_img_URL",
  "database_filter_released",
  "dev_mode",
  "silent",
  "success",
];
export const buttonCells = [
  "assetDetail",
  "scoreDetail",
  "manageShelf",
  "total_votes",
  "channels",
];
export const dateCells = [
  "date",
  "release_date",
  "filter_release_date",
  "modified_at",
  "episode_filter_release_date",
  "created_at",
  "database_filter_release_date",
];
export const platformCells = ["episode_platform_id", "platform_id"];

export const copyValueCells = [
  "comment",
  "created_at",
  "credit_start_time",
  "display_name",
  "email",
  "imdb_id",
  "modified_at",
  "platform_id",
  "user_id",
  "name",
  "episode_name",
  "series_name",
  "video_id",
  "user",
];

export const onOffCells = ["released"];

export const resolutionCells = ["resolved_at", "reported_at", "unreproducible_at"];

export const goToMovieCells = ["video_identifier", "partner_identifier", ""];
export const tooltipCells = [
  "display_name",
  "series_name",
  "episode_name",
  "name",
];

export const toggleHiddenColumns = [
  // "browser_name",
  "browser_version",
  "device_type",
  "os_name",
  "os_version_name",
  "os_version",
  "extension_version",
  "ip_address",
  "filter_release_date",
  "database_filter_release_date",
  "dev_mode",
  "database_filter_released",
  "user_id",
  "modified_at",
  "email",
  "user_id",
  "device_type",
  "asset_type_id",
  "report_duration_mismatch_id",
];

function filterToNull(rows, id, filterValue) {
  if (filterValue === false) return rows;
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return !rowValue;
  });
}
function filterToV2Classification(rows, id, filterValue) {
  if (filterValue === false) return rows;
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue === 2;
  });
}
function filterNotSearchable(rows, id, filterValue) {
  if (filterValue === false) return rows;
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue === false;
  });
}
function isTrue(rows, id, filterValue) {
  if (filterValue === false) return rows;
  const rowsfiltered = rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue === true;
  });
  return rowsfiltered;
}
function isFalse(rows, id, filterValue) {
  if (filterValue === false) return rows;
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue === false;
  });
}
function lastThirtyDays(rows, id, filterValue) {
  if (filterValue === false) return rows;
  return rows.filter((row) => {
    const rowValue = row.values[id];
    const thirtyDaysAgo = dayjs().subtract(30, "day");
    return dayjs(rowValue).isAfter(thirtyDaysAgo);
  });
}
function successFilter(rows, id, filterValue) {
  if (filterValue === true) {
    // Checking for boolean true
    return isTrue(rows, id[0], filterValue);
  } else if (filterValue === false) {
    // Checking for boolean false
    return isFalse(rows, id[0], filterValue);
  }
  return rows; // If filterValue is undefined, don't filter the rows
}

const useSharedTableData = () => {
  const ratingColumns = useMemo(() => {
    return {
      col: [
        {
          Header: "Movie Dislay Title",
          accessor: "display_name",
        },
        {
          Header: "Clearplay Score",
          accessor: "clearplay_score",
        },
        {
          Header: "Community Votes (qty)",
          accessor: "community_votes",
          sortType: "basic",
          canFilter: true,
        },
        {
          Header: "Community Score",
          accessor: "community_score",
          sortType: "basic",
          canFilter: true,
        },
        {
          Header: "IMDB ID",
          accessor: "imdb_id",
        },
        {
          Header: "Score Detail",
          accessor: "scoreDetail",
        },
        {
          accessor: "asset_score_id",
        },
      ],
      hidden: ["asset_score_id"],
    };
  }, []);

  const channelColumns = useMemo(() => {
    return {
      col: [
        {
          Header: "Channel Display Name",
          accessor: "channel_display_name",
        },
        {
          Header: "Channel Name",
          accessor: "channel_name",
        },
        {
          Header: "Channel ID",
          accessor: "channel_id",
        },
        {
          Header: "Channel Disabled",
          accessor: "channel_disabled",
          sortType: "basic",
          canFilter: true,
        },
        {
          Header: "Channel Maintenance Mode",
          accessor: "channel_maintenance_mode",
          sortType: "basic",
          canFilter: true,
        },
        {
          Header: "Channel Maintenance Message",
          accessor: "channel_maintenance_message",
        },
      ],
      hidden: [],
    };
  }, []);

  const platformColumns = useMemo(() => {
    return {
      col: [
        {
          Header: () => (
            <HeaderTooltip
              title="Friendly name for the platform (Read-only)"
              arrow
            >
              <span>Platform Name</span>
            </HeaderTooltip>
          ),
          accessor: "name",
          disableSortBy: true,
        },
        {
          Header: () => (
            <HeaderTooltip
              title="Completely disable the platform. Note: Can still show up in platform search list, so you'll probably want to mark the platform as not searchable too."
              arrow
            >
              <span>⌚ &nbsp; Enabled</span>
            </HeaderTooltip>
          ),
          accessor: "enabled",
          disableSortBy: true,
        },
        {
          Header: () => (
            <HeaderTooltip
              title="Whether a platform requires an active membership to filter. Controls whether an active subscription is required to filter on a platform."
              arrow
            >
              <span>⌚ &nbsp; Membership Required</span>
            </HeaderTooltip>
          ),
          accessor: "requires_membership",
          disableSortBy: true,
        },
        {
          Header: () => (
            <HeaderTooltip
              title="Disable movies for a platform. Disables most/all playback filtering. Disable this if you want a maintenance message to show."
              arrow
            >
              <span>⌚ &nbsp; Movies Active</span>
            </HeaderTooltip>
          ),
          accessor: "moviesActive",
          disableSortBy: true,
        },
        {
          Header: () => (
            <HeaderTooltip
              title="Disable shows. Doesn't work everywhere, because not all platforms can properly identify movie/show/episode."
              arrow
            >
              <span>⌚ &nbsp; Shows Active</span>
            </HeaderTooltip>
          ),
          accessor: "showsActive",
          disableSortBy: true,
        },
        {
          Header: () => (
            <HeaderTooltip
              title="Remove platform from the list of 'browseable' platforms (magnifying glass)."
              arrow
            >
              <span>⌚ &nbsp;Searchable</span>
            </HeaderTooltip>
          ),
          accessor: "isSearchable",
          disableSortBy: true,
        },
        {
          Header: () => (
            <HeaderTooltip
              title="Displays banner message to customer that platform is currently down. Will use the 'Maintenance Message' if it's given. Customers can still browse the shelves and search."
              arrow
            >
              <span>🌐 &nbsp; Web Banner</span>
            </HeaderTooltip>
          ),
          accessor: "web_banner",
          disableSortBy: true,
        },
        {
          Header: () => (
            <HeaderTooltip
              title="Allows movies to be clicked/redirected for the platform in search. If disabled, this will popup a message to the customer that it is currently unavailable when clicked."
              arrow
            >
              <span>🌐 &nbsp; Web Search</span>
            </HeaderTooltip>
          ),
          accessor: "web_search",
          disableSortBy: true,
        },
        {
          Header: () => (
            <HeaderTooltip
              title="Allows movies to be clicked/redirected for the platform in the shelves. If disabled, this will popup a message to the customer that it is currently unavailable when clicked."
              arrow
            >
              <span>⌚ &nbsp; Web Shelves</span>
            </HeaderTooltip>
          ),
          accessor: "web_shelves",
          disableSortBy: true,
        },
        {
          Header: () => (
            <HeaderTooltip
              title="Adjusts the global offset for the platform. Note: This actually isn't in the extension. It is either in Database, Streamer/FilterRetrieval API, or is not used anymore."
              arrow
            >
              <span>⌚ &nbsp; Time Offset</span>
            </HeaderTooltip>
          ),
          accessor: "time_offset",
          disableSortBy: true,
        },
        {
          Header: () => (
            <HeaderTooltip
              title="Require at least this version of the extension. Disables a platform if the current extension isn't at least this version."
              arrow
            >
              <span>⌚ &nbsp; Minimum Version</span>
            </HeaderTooltip>
          ),
          accessor: "minimumVersion",
          disableSortBy: true,
        },
        // {
        //   Header: () => (
        //       <span>Channels</span>
        //   ),
        //   accessor: "channels",
        //   disableSortBy: true,
        // },
        {
          Header: () => (
            <HeaderTooltip
              title="This message is shown to communicate to the customer. Only visible if platform is enabled and 'Movies Active' is disabled. On the web, the message is displayed on page for platform."
              arrow
            >
              <span>⌚ &nbsp; Maintenance Message</span>
            </HeaderTooltip>
          ),
          accessor: "maintenanceMessage",
          disableSortBy: true,
        },
        {
          accessor: "platform_id",
        },
      ],
      hidden: ["platform_id"],
    };
  }, []);
  const movieScoreDetailColumn = useMemo(() => {
    return {
      col: [
        {
          Header: "User ID",
          accessor: "userId",
        },
        {
          Header: "Email",
          accessor: "email",
        },
        {
          Header: "Platform",
          accessor: "platform_id",
        },
        {
          Header: "Video Identifier",
          accessor: "partner_identifier",
        },
        {
          Header: "Score",
          accessor: "score",
          sortType: "basic",
          canFilter: true,
        },
        {
          Header: "Comments",
          accessor: "comment",
        },
        {
          accessor: "clearplay",
        },
        {
          Header: "Date",
          accessor: "date",
          sortType: "basic",
          canFilter: true,
        },
        {
          Header: "Vote Qty",
          accessor: "count",
          sortType: "basic",
          canFilter: true,
        },
      ],
      hidden: ["clearplay"],
    };
  }, []);

  const movieDetailColumns = useMemo(() => {
    return {
      col: [
        { Header: "Name", accessor: "name" },
        { Header: "Platform", accessor: "platform_id" },
        { Header: "Video Identifier", accessor: "video_identifier" },
        {
          accessor: "asset_id",
        },
        { accessor: "filter_id" },
        {
          Header: "Imdb Id",
          accessor: "imdb_id",
          Filter: FilterToggle,
          filter: filterToNull,
        },
        { Header: "Theatrical Release", accessor: "release_date" },
        { Header: "Filter Release", accessor: "filter_release_date" },
        {
          Header: "Duration",
          accessor: "duration",
        },
        {
          Header: "Display Duration",
          accessor: "display_duration",
        },
        {
          Header: "Video Duration",
          accessor: "video_duration",
        },
        {
          Header: "Credit Start",
          accessor: "credit_start_time",
        },
        {
          Header: "Class Id",
          accessor: "classification_version_id",
          canFilter: true,
          Filter: FilterToggle,
          filter: filterToV2Classification,
        },
        {
          Header: "Searchable",
          accessor: "searchable",
          sortType: (rowA, rowB, id, desc) => {
            if (rowA.values[id] > rowB.values[id]) return 1;
            if (rowB.values[id] > rowA.values[id]) return -1;
            return 0;
          },
          canFilter: true,
          Filter: FilterToggle,
          filter: filterNotSearchable,
        },
        {
          Header: "Released",
          accessor: "filter_released",
          sortType: (rowA, rowB, id, desc) => {
            if (rowA.values[id] > rowB.values[id]) return 1;
            if (rowB.values[id] > rowA.values[id]) return -1;
            return 0;
          },
          canFilter: true,
          Filter: FilterToggle,
        },
        {
          Header: "Poster URL",
          accessor: "img_URL",
          canFilter: true,
          Filter: FilterToggle,
          filter: filterToNull,
        },
        {
          Header: "Movie Details",
          accessor: "assetDetail",
        },
        { accessor: "display_name", canFilter: false },
        { accessor: "type_id", canFilter: false },
      ],
      hidden: [
        "asset_score_id",
        "asset_identifier_id",
        "display_name",
        "filter_id",
        "type_id",
        "asset_id",
      ],
    };
  }, []);

  const customerCommentsColumns = useMemo(() => {
    return {
      col: [
        {
          Header: "Display Name",
          accessor: "display_name",
          Cell: ({ row, column }) => {
            return (
              <CopyToFilter
                row={row}
                column={column}
                isCopyText={true}
                value={row.values["display_name"]}
              />
            );
          },
        },
        {
          Header: "Filter Release",
          accessor: "filter_release_date",
        },
        {
          Header: "Status",
          accessor: "released",
          sortType: "basic",
          canFilter: true,
        },
        {
          Header: "Platform",
          accessor: "platform_id",
        },
        {
          Header: "Video Identifier",
          accessor: "partner_identifier",
        },
        {
          Header: "User ID",
          accessor: "user_id",
        },
        {
          Header: "Email",
          accessor: "email",
        },
        {
          Header: "Score",
          accessor: "score",
        },
        {
          Header: "Comments",
          accessor: "comment",
        },
        {
          Header: "URL",
          accessor: "url",
        },
        {
          Header: "Extension Version",
          accessor: "extension_version",
        },
        {
          Header: "OS Version",
          accessor: "os_version",
        },
        {
          Header: "OS Version Name",
          accessor: "os_version_name",
        },
        {
          Header: "Os Name",
          accessor: "os_name",
        },
        {
          Header: "Device",
          accessor: "device_type",
        },
        {
          Header: "Broswer Version",
          accessor: "browser_version",
        },
        {
          Header: "Browser Name",
          accessor: "browser_name",
        },
        {
          Header: "Date",
          accessor: "created_at",
          Filter: FilterToggle,
          filter: lastThirtyDays,
        },
        // {
        //   Header: 'Total Votes',
        //   accessor: 'total_votes',
        //   sortType: 'basic',
        //   canFilter: true
        // },
        {
          Header: "Un-Reproducible At",
          accessor: "unreproducible_at",
        },
        {
          Header: "Reported",
          accessor: "reported_at",
          sortType: "basic",
          canFilter: true,
          Filter: FilterToggle,
          filter: filterToNull,
        },
        {
          Header: "Resolved",
          accessor: "resolved_at",
          sortType: "basic",
          canFilter: true,
          Filter: FilterToggle,
          filter: filterToNull,
        },
        {
          accessor: "imdb_id",
        },
        { accessor: "asset_score_id" },
      ],
      hidden: ["asset_score_id", "imdb_id", "total_votes"],
    };
  }, []);

  const showDetailColumns = useMemo(() => {
    return {
      col: [
        {
          Header: "Series Info",
          columns: [
            {
              Header: "Series Name",
              accessor: "series_name",
              aggregate: "count",
              Aggregated: ({ value }) => `${value} Names`,
            },
            {
              Header: "Platform",
              accessor: (data) =>
                displayProviderMap[data.platform] ||
                `${data.platform}`,
            },
            {
              Header: "Series Imdb",
              accessor: "series_imdb_id",
            },
            {
              Header: "Series Asset Id",
              accessor: "series_asset_id",
            },
            {
              Header: "Series Partner Id",
              accessor: "series_partner_identifier",
            },
            {
              accessor: "series_platform_title",
            },
            {
              Header: "Series Video Id",
              accessor: "series_video_id",
            },
            {
              accessor: "series_platform_id",
            },
          ],
          accessor: "series_info",
          disableSortBy: true,
        },
        {
          Header: "Episode Info",
          columns: [
            { Header: "Episode Name", accessor: "episode_name" },

            { Header: "Season", accessor: "season_number" },
            { Header: "Episode", accessor: "episode_number" },
            {
              Header: "Asset Id",
              accessor: "episode_asset_id",
            },
            {accessor: "episode_platform_id"},
            { accessor: "episode_asset_identifier_id" },
            { accessor: "episode_filter_id" },
            {
              Header: "Imdb Id",
              accessor: "episode_imdb_id",
              Filter: FilterToggle,
              filter: filterToNull,
            },
            { accessor: "episode_display_name" },
            {accessor: "episide_platform_title"},
            {
              Header: "Filter Release",
              accessor: "episode_filter_release_date",
            },
            {
              Header: "Duration",
              accessor: "episode_duration",
            },
            {
              Header: "Display Duration",
              accessor: "episode_display_duration",
            },
            {
              Header: "Video Duration",
              accessor: "episode_video_duration",
            },
            { Header: "Credit Start", accessor: "episode_credit_start_time" },

            { accessor: "type_id", show: false },
            {
              Header: "Class Id",
              accessor: "episode_classification_version_id",
              canFilter: true,
              Filter: FilterToggle,
              filter: filterToV2Classification,
            },
            {
              Header: "Searchable",
              accessor: "series_searchable",
              sortType: (rowA, rowB, id, desc) => {
                if (rowA.values[id] > rowB.values[id]) return 1;
                if (rowB.values[id] > rowA.values[id]) return -1;
                return 0;
              },
              Filter: FilterToggle,
              filter: filterNotSearchable,
            },
            {
              Header: "Released",
              accessor: "episode_filter_released",
              sortType: (rowA, rowB, id, desc) => {
                if (rowA.values[id] > rowB.values[id]) return 1;
                if (rowB.values[id] > rowA.values[id]) return -1;
                return 0;
              },

              Filter: FilterToggle,
            },
            { accessor: "season_asset_id", show: false },
            {
              Header: "Poster URL",
              accessor: "series_img_URL",
              Filter: FilterToggle,
              filter: filterToNull,
            },

            {
              Header: "Show Details",
              accessor: "assetDetail",
            },
            { accessor: "video_identifier", show: false },
            { accessor: "series_display_name", show: false },
          ],
          accessor: "episode_info",
          disableSortBy: true,
        },
      ],

      hidden: [
        "series_display_name",
        "asset_score_id",
        "episode_display_name",
        "episode_asset_identifier_id",
        "credit_start_time",
        "season_asset_id",
        "episode_filter_id",
        'series_platform_id',
        "series_platform_title"
      ],
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const movieShelvesColumns = useMemo(() => {
     const miniProviderMap = {
      7: 'Amazon',
      9: 'Netflix',
      10: 'Disney+',
      11: 'Max',
      13: 'Apple TV+',
    };
  const platformColumns = Object.keys(miniProviderMap).map((platformId) => ({
    Header: displayProviderMap[platformId],
    accessor: (row) => row.platformAssetCounts[platformId] || 0, 
    sortType: "basic",
    canFilter: true,
    Cell: ({ value }) => (
      <div style={{ textAlign: "center" }}>
        {value}
      </div>
    ),
  }));

  return {
    col: [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Sort Type",
        accessor: "asset_list_sort_id",
      },
      {
        Header: "Priority",
        accessor: "priority",
        sortType: "basic",
        canFilter: true,
      },
      {
        Header: "Description",
        accessor: "description",
      },
      {
        Header: "Asset List ID",
        accessor: "asset_list_type_id",
      },
      ...platformColumns, 
      {
        Header: "Active",
        accessor: "isActive",
        Filter: FilterToggle,
        filter: isTrue,
        sortType: "basic",
        canFilter: true,
      },
      {
        Header: "Manage Shelf",
        accessor: "manageShelf",
      },
    ],
    hidden: ['asset_list_type_id'],
  };
}, []);
  

  const durationMismatches = useMemo(() => {
    return {
      col: [
        // {
        //   id: 'expander', // 'id' is required for columns without an accessor
        //   Header: () => null, // No header content
        //   Cell: ({ row }) => (
        //     <span {...row.getToggleRowExpandedProps()}>
        //       {row.isExpanded ? '🔽' : '▶️'}
        //     </span>
        //   ),
        //   // You can set the width as needed
        //   width: 30
        // },
        { Header: "Name", accessor: "video_name" },
        {
          Header: "Video Id",
          accessor: "video_id",
          Cell: ({ row }) => {
            return <CopyToFilter value={row.values["video_id"]} />;
          },
        },
        {
          Header: "Asset Type ID",
          accessor: "asset_type_id",
        },
        {
          Header: "Asset Type Name",
          accessor: "asset_type_name",
        },
        {
          Header: "Platform ID",
          accessor: "platform_id",
          sortType: "basic",
          canFilter: true,
        },
        {
          Header: "URL",
          accessor: "url",
        },
        {
          Header: "Asset IMDb ID",
          accessor: "asset_imdb_id",
        },

        {
          Header: "Customer",
          accessor: "user",
        },
        {
          Header: "IP Address",
          accessor: "ip_address",
        },
        {
          Header: "Extension Version",
          accessor: "extension_version",
        },
        {
          Header: "Video Duration",
          accessor: "video_duration",
        },
        {
          Header: "Asset Duration",
          accessor: "asset_duration",
        },
        {
          Header: "Video Time",
          accessor: "video_time",
        },
        {
          Header: "Database Asset Duration",
          accessor: "database_asset_duration",
        },
        {
          Header: "Browser Name",
          accessor: "browser_name",
        },
        {
          Header: "Browser Version",
          accessor: "browser_version",
        },

        {
          Header: "Device Type",
          accessor: "device_type",
        },
        // {
        //   Header: "Email",
        //   accessor: "email",
        // },
        {
          Header: "OS Name",
          accessor: "os_name",
        },
        {
          Header: "OS Version Name",
          accessor: "os_version_name",
        },
        {
          Header: "OS Version",
          accessor: "os_version",
        },

        {
          Header: "Filter Release Date",
          accessor: "filter_release_date",
        },
        {
          Header: "Modified At",
          accessor: "modified_at",
        },
        {
          Header: "User ID",
          accessor: "user_id",
        },

        {
          Header: "Database Filter Release Date",
          accessor: "database_filter_release_date",
        },
        // {
        //   Header: "Silent",
        //   accessor: "silent",
        //   sortType: "basic",
        //   canFilter: true,
        // },
        {
          Header: "Dev Mode",
          accessor: "dev_mode",
          sortType: "basic",
          canFilter: true,
        },
        {
          Header: "Database Filter Released",
          accessor: "database_filter_released",
          sortType: "basic",
          canFilter: true,
        },
        {
          Header: "Trigger Date",
          accessor: "created_at",
          sortType: "basic",
        },
        {
          Header: "Un-Reproducible At",
          accessor: "unreproducible_at",
        },
        {
          Header: "Reported At",
          accessor: "reported_at",
        },
        {
          Header: "Resolved At",
          accessor: "resolved_at",
        },
        {
          accessor: "report_duration_mismatch_id",
        },
      ],
      hidden: [
        "modified_at",
        "email",
        "user_id",
        "device_type",
        "asset_type_id",
        "report_duration_mismatch_id",
      ],
    };
  }, []);

  const sneezeTestColumns = useMemo(() => {
    return {
      col: [
        { Header: "IMDb ID", accessor: "asset_imdb_id" },
        {
          Header: "Created At",
          accessor: "created_at",
          sortType: "basic",
        },
        {
          Header: "User",
          accessor: (data) => (data.email ? data.email : data.user_id),
        },
        {
          Header: "Platform",
          accessor: (data) =>
            displayProviderMap[data.platform_id] || `ID: ${data.platform_id}`,
        },
        { Header: "Type", accessor: "asset_type_name" },
        { Header: "Movie Name", accessor: "database_asset_name" },
        { Header: "Video ID", accessor: "video_id" },
        {
          Header: "URL",
          accessor: "url",
          Cell: ({ value }) => (
            <OpenUrl href={value} target="_blank" rel="noopener noreferrer">
              Open Url
            </OpenUrl>
          ),
        },
        // { Header: "Low", accessor: "low" },
        {
          Header: "Success",
          accessor: (data) => (data.success ? "Yes" : "No"),
        },
        { Header: "Test Result", accessor: "test_result" },
        { Header: "Test Duration", accessor: "test_duration" },
        { Header: "Extension Version", accessor: "extension_version" },
        { Header: "Device Type", accessor: "device_type" },
        {
          Header: "Browser",
          accessor: (data) => `${data.browser_name} ${data.browser_version}`,
        },
        {
          Header: "OS",
          accessor: (data) => `${data.os_name} ${data.os_version_name}`,
        },
        { Header: "OS Version", accessor: "os_version" },
        { Header: "IP Address", accessor: "ip_address" },
        { Header: "Release Date", accessor: "database_filter_release_date" },
        {
          Header: "Dev Mode",
          accessor: (data) => (data.dev_mode ? "Enabled" : "Disabled"),
        },
      ],
      hidden: ["ip_address", "user_id", "database_asset_name"],
    };
  }, []);

  const skipResumeAccuracyColumns = useMemo(() => {
    return {
      col: [
        { Header: "IMDb ID", accessor: "asset_imdb_id" },
        {
          Header: "Created At",
          accessor: "created_at",
          sortType: "basic",
        },

        {
          Header: "User",
          accessor: (data) => (data.email ? data.email : data.user_id),
        },
        {
          Header: "Platform",
          accessor: (data) =>
            displayProviderMap[data.platform_id] || `ID: ${data.platform_id}`,
        },
        { Header: "Asset Type", accessor: "asset_type_name" },
        { Header: "Movie Name", accessor: "database_asset_display_name" },
        { Header: "Video ID", accessor: "video_id" },
        {
          Header: "Release Date",
          accessor: "database_filter_release_date",
        },
        {
          Header: "URL",
          accessor: "url",
          Cell: ({ value }) => (
            <OpenUrl href={value} target="_blank" rel="noopener noreferrer">
              Open Url
            </OpenUrl>
          ),
        },
        { Header: "Low", accessor: "low" },
        { Header: "Event Number", accessor: "event_number" },
        { Header: "Event Resume Time", accessor: "event_resume_time" },
        { Header: "Actual Resume Time", accessor: "actual_resume_time" },
        { Header: "Resume Time Diff", accessor: "resume_time_diff" },
        { Header: "Extension Version", accessor: "extension_version" },
        { Header: "Device Type", accessor: "device_type" },
        {
          Header: "Browser",
          accessor: (data) => `${data.browser_name} ${data.browser_version}`,
        },
        {
          Header: "OS",
          accessor: (data) => `${data.os_name} ${data.os_version_name}`,
        },
        {
          Header: "Session Id",
          accessor: "session_id",
        },
      ],
      hidden: ["ip_address", "user_id"],
    };
  }, []);

  return {
    ratingColumns,
    platformColumns,
    movieScoreDetailColumn,
    movieDetailColumns,
    customerCommentsColumns,
    showDetailColumns,
    movieShelvesColumns,
    durationMismatches,
    channelColumns,
    skipResumeAccuracyColumns,
    sneezeTestColumns,
  };
};

export default useSharedTableData;
