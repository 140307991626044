import styled, {keyframes} from "styled-components/macro";
import * as theme from "../../theme";
import { AppBar, Button, IconButton, Toolbar, ButtonBase } from "@mui/material";

export const NavBar = styled(AppBar)`
  && {
    height: 70px;
    background: rgb(9, 11, 19);
    /* ${(props) =>
      props.transparent ? "rgb(9, 11, 19)" : "transparent"}; */
    box-shadow: ${(props) => (props.transparent ? "inherit" : "none")};
    position: fixed;
  }
`;
export const PreNav = styled(AppBar)`
  && {
    height: 70px;
    background-color: ${(props) =>
      props.transparent ? "transparent" : "rgb(9, 11, 19)"};
    box-shadow: ${(props) => (!props.transparent ? "none" : "inherit")};
    max-width: 2250px;
    position: fixed;
  }
`;
export const NavTool = styled(Toolbar)`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-content: center;
`;
export const LogoButton = styled(IconButton)`
  position: relative;
`;

export const Logo = styled.img`
    width: clamp(100px, 8vw, 150px);
`;

export const Account = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  &:hover {
    cursor: pointer;
  }
`;
export const NavIcon = styled.img`
  margin-left: 20px;
  width: 30px;
  height: auto;
  &.pre-login {
    margin-right: 20px;
    &.mobile {
      width: 30px;
      margin: 0 10px 0 0;
    }
  }
  &.search-icon {
    height: auto;
    width: 70px;
  }
  &:hover {
    cursor: pointer;
    filter: brightness(0) invert(1);
  }
  @media ${theme.breakpoint.md} {
    margin-left: 30px;
    margin-right: 50px;
    width: 30px;
    &.search-icon {
      width: 89px;
    }
  }
`;


export const HomeLink = styled.div`
  display: flex;
  color: #999;
  justify-content: center;
  align-items: center;
  font-size: 18px;
`;
export const HomeIcon = styled.img`
  height: auto;
  margin-left: 20px;
  width: 70px;
  &.pre-login {
    margin-right: 20px;
    &.mobile {
      width: 20px;
      margin: 0 10px 0 0;
    }
  }
  &:hover {
    cursor: pointer;
    filter: brightness(0) invert(1);
  }
  @media ${theme.breakpoint.md} {
    margin-left: 30px;
    width: 80px;
  }
`;


export const Login = styled.div`
  border: 1px solid #ffffff;
  border-radius: 3px;
  opacity: 1;
  padding: 5px 9px;
  color: white;
  display: flex;
  flex-direction: row;
  font-family: "Avenir", "Avenir-Condensed";
  font-size: clamp(10px, 1vw, 14px);

  @media ${theme.breakpoint.md} {
    padding: 5px 20px;
  }
`;

export const SignupButton = styled(ButtonBase)`
  && {
    height: min(max(20px, 5vw), 35px); ;
    font-size: min(max(9px, 1.1vw), 14px);
    padding: min(max(6px, 1.1vw), 10px);
    margin-right: clamp(15px, 1vw, 20px);
    font-weight: 900;
    font-family: "Avenir", "Avenir-Condensed";
    color: black;
    background: #ffcc66 0% 0% no-repeat padding-box;
    border-radius: 3px;
    opacity: 1;
  }
`;

export const DvdSignin = styled(Button)`
  && {
    display: none;
    @media ${theme.breakpoint.md} {
      display: block;
      background-color: transparent;
      text-transform: none;
      font-weight: 400;
      color: #999;
      font-size: clamp(12px, 4vw, 18px);
      box-shadow: 0px 3px 6px #00000041;
      border: 2px solid #999;
      border-radius: 10px;
      opacity: 1;
      padding: 5px 10px;
      width: 100%;
      align-self: center;
      width: clamp(175px, 50vw, 200px);
      img {
        max-height: clamp(12px, 3vw, 20px);
        margin-left: 5px;
        margin-top: 2px;
      }
    }
  }
`;

export const SearchIcon = styled.img`
  height: auto;
  width: 20px;
  @media ${theme.breakpoint.md} {
    width: 25px;
  }
`;

export const IconWrapper = styled.div`
  height: auto;
  display: flex;
  color: #999;
  span {
    margin: 3px 0 0 5px;
  }
  &:hover {
    color: #fff;
    img {
      cursor: pointer;
      filter: brightness(0) invert(1);
    }
  }
`;

export const AdminIndicator = styled.div`
  font-weight: 900;
  font-size: 9px;
  background: red;

  width: fit-content;
  padding: 3px;
  border: 1px solid black;
  color: black;
  border-radius: 4px;
  position: absolute;
  left: 35px;
  z-index: -1;
  bottom: 0;
`;

export const HelpLink = styled.a`
  margin-left: 15px;
  color: rgba(225, 225, 225, 0.64);
  font-size: 15px;
  cursor: pointer;
  text-decoration: none;

  @media ${theme.breakpoint.md} {
    font-size: 18px;
  }
  &:hover {
    color: white;
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const DropdownMenu = styled.div`
  display: block;
  position: absolute;
  top: 45px; 
  right: 0;
  background-color: rgb(9, 11, 19);
  border-radius: 4px;
  width: auto; /* Adjust based on content */
  min-width: 160px; /* Ensures the dropdown is not too narrow */
  z-index: 1000;
  animation: ${fadeIn} 0.2s ease-out;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2); /* Optional: adds a subtle shadow */
  padding: 20px 0;
  font-size: 18px;
`;

export const DropdownItem = styled.div`
  padding: 10px 40px;
  color: rgb(153, 153, 153);
  border-radius: 16px;
  &:hover {
    filter: brightness(0) invert(1);
  }
  cursor: pointer;
`;