import { signal } from '@preact/signals-react';
import { Flix } from '../../../app/app.types';
import { AssetPagination } from '../../adminFeatures/assets/assetTypes';
import { assetLimit } from '../../flix/useFlix';

export const assetPagination = signal<AssetPagination>({} as AssetPagination);

const makeBookImage = (imageUrl: string) => {
  const copy = JSON.parse(JSON.stringify(imageUrl))
  const urlParts = copy.split(".").slice(0, -1);
  const parts = imageUrl.split(".");
  const extension = parts.pop();
  const img = urlParts.join("-") + "-book-cover." + extension;
  return img
}

export const uniqueShelf = (films: Flix[], category: string) => {
  switch (category) {
    case 'Groundhog Day':
      let repeatFilms: Partial<Flix>[] = [films[0]];
      for (let i = 0; i < 5; i++) {
        repeatFilms.push(films[0]);
      }
      repeatFilms.push({
        asset_id: '7095',
        asset_identifier_id: '14176',
        category: 'Groundhog Day',
        child: null,
        description:
          'A cynical weatherman is forced to continuously re-live the worst day of his life until he learns to become a better person.',
        duration: '01:41:01',
        format_id: '1',
        genre: null,
        identifiers: '',
        img_url:
          'https://clearplayasset-imgurl.s3.amazonaws.com/ned-ryerson.jpg',
        name: 'Groundhog Day (Amazon)',
        number: '0',
        platform_id: '7',
        platform_title: '',
        platforms: '',
        provider_id: '3',
        rating_id: '1',
        release_date: '1993-02-12',
        type_id: '1',
        video_identifier: 'B000SP1SH6',
      });
      return repeatFilms;
    case 'World Book Day (April 23, 2023)':
      const bookFilms = films.map((f) => {
        return {
          ...f,
          img_url: makeBookImage(f.img_url ? f.img_url : '')
        };
      });
      return bookFilms;
    case 'National Coaches Day':
      const sortList = [
        'Rocky',
        'The Karate Kid',
        'Remember the Titans',
        'Coach Carter',
        'We Are Marshall',
        'Glory Road',
        'McFarland, USA',
        'Little Giants',
        'Cool Runnings',
        'A League of Their Own',
        'Hoosiers',
      ];
      const sortedFilmList = films.sort((a, b) => {
        return (
          sortList.indexOf(a.display_name) - sortList.indexOf(b.display_name)
        );
      });
      sortedFilmList.unshift({
        name: 'tc-video',
        asset_id: '12345',
        asset_identifier_id: '',
        category: 'uniqueFilm',
        child: null,
        description: 'https://player.vimeo.com/video/756886358?h=9b42917813',
        duration: '',
        format_id: '',
        genre: null,
        identifiers: '',
        img_url: null,
        number: '',
        platform_id: '',
        platform_title: '',
        platforms: '',
        provider_id: '',
        rating_id: '',
        release_date: '',
        type_id: '',
        video_identifier: '',
        display_name: '',
        shelf_name: '',
        ticket_img_URL: undefined,
        ticket_punched_img_URL: undefined,
      });
      return sortedFilmList;

    case 'Boxing':
    case "Boxing ('Creed 3' is now in theaters!)":
    case 'Boxing (\'Creed 3\' hits theaters this Friday!)':
      films.unshift({
        name: 'tc-video',
        asset_id: '12346',
        asset_identifier_id: '',
        category: 'uniqueFilm',
        child: null,
        description: 'https://player.vimeo.com/video/804439464',
        duration: '',
        format_id: '',
        genre: null,
        identifiers: '',
        img_url: null,
        number: '',
        platform_id: '',
        platform_title: '',
        platforms: '',
        provider_id: '',
        rating_id: '',
        release_date: '',
        type_id: '',
        video_identifier: '',
        display_name: '',
        shelf_name: '',
        ticket_img_URL: undefined,
        ticket_punched_img_URL: undefined
      })
      films.push({
        name: 'tc-video',
        asset_id: '12347',
        asset_identifier_id: '',
        category: 'uniqueFilm',
        child: null,
        description: 'https://player.vimeo.com/video/804442886',
        duration: '',
        format_id: '',
        genre: null,
        identifiers: '',
        img_url: null,
        number: '',
        platform_id: '',
        platform_title: '',
        platforms: '',
        provider_id: '',
        rating_id: '',
        release_date: '',
        type_id: '',
        video_identifier: '',
        display_name: '',
        shelf_name: '',
        ticket_img_URL: undefined,
        ticket_punched_img_URL: undefined
      })
      return films
    default:
      return films;
  }
};


export const initializePaginationForShelf = (asset_list_type_id: string | number, initialOffset = 0, initialLimit = assetLimit) => {
  if (!assetPagination.value[asset_list_type_id]) {
    assetPagination.value[asset_list_type_id] = {
      offset: signal(initialOffset),
      limit: signal(initialLimit),
      initialFetch: signal(true),
      isEnd: signal(false)
    };
  }
};