import styled from "styled-components/macro";

export const FooterContainer = styled.footer`
  color: rgb(153, 153, 153);
  text-align: center;
  position: absolute;
  height: clamp(200px, 30vw, 317px);
  bottom: 0;
  left: 0;
  width: 100vw;
  text-align: center;
  background-color: #f0f0f0; /* Set your desired background color */
  padding: 10px 0px; /* Adjust padding as needed */
  z-index: 100; /* Ensure the footer is on top of other content */
  display: none; /* Initially hide the footer */
  background-image: url(https://d9hhrg4mnvzow.cloudfront.net/join.clearplay.com/browser/4aa62644-backgorund_11hc15v1hc0d80000eb000.png);

  &.visible {
    display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  }
`;

export const CopyrightText = styled.div`
 margin-top: clamp(20px, 1.5vw, 40px);
font-size: clamp(10px, 1.5vw, 16px);
span {
  &:hover {
    cursor: pointer;
    color: gray;
  }
}
`;

export const LinkContainer = styled.div`
  margin-top: 10px;
  margin-bottom: clamp(10px, 1.5vw, 20px);
  display: flex;
  flex-direction: row;
  padding: 0 20px;
  a {
    text-decoration: none;
    color: #999;
    margin-right: clamp(10px, 4vw, 60px);
    margin-left: clamp(10px, 4vw, 60px);
    font-size: clamp(12px, 1.5vw, 18px);
    &:hover {
      color: white;
    }
  }
`;

export const DisclaimerText = styled.div`
 padding: 0 20px;
  margin-top: clamp(10px, 1.5vw, 20px);
  font-size: 12px; /* Adjust the font size as needed */
  max-width: 630px;
  text-align: center;
  font-size: clamp(10px, 1.5vw, 16px);
  letter-spacing: .5;
  margin-bottom: 20px;
`;

export const CompanyAddress = styled.div`
 padding: 0 20px;
  margin-top: clamp(10px, 1.5vw, 20px);
  font-size: clamp(10px, 1.5vw, 16px);
`;

export const Spacer = styled.span``;
