import { providerMap } from '../../../app/app.types';
import { useCopyToClipboard, utcDate } from '../../utils';
import { CopyValue } from '../CustomCell.styles';
import { CustomTooltip } from '../HeaderTooltip.styled';
import { tooltipCells } from '../useSharedTableData';

interface ICopyCells {
  row: any;
  value: string;
  column: any;
}

export const CopyCells: React.FC<ICopyCells> = ({ row, value, column }) => {

  const { copyToClipBoard} = useCopyToClipboard()
  let title = ''
  const modVal =
    column.id === 'modified_at'
      ? utcDate(value)
      : typeof value === 'number'
      ? providerMap[value]
      : value;

    if (tooltipCells.includes(column.id)) {
      switch (column.id) {
        case 'name':
          title = row.values.display_name
          break
        case 'series_name':
          title = row.values.series_display_name
          break
        // case 'episode_name':
        //   title = row.values.episode_display_name
        //   break
        default:
          break
      }
    }
  return (
    <CustomTooltip title={title} placement='top' arrow={true}>
    <CopyValue onClick={() => value && copyToClipBoard(modVal.replace(/\((?![0-9]{4}\b)[^)]+\)/g, '').trim(), column.id)}>
      {modVal}
    </CopyValue>
    </CustomTooltip>
  );
  // } else if (
  //   row.canExpand === false && ['series_asset_id', 'series_imdb_id', 'series_searchable'].includes(
  //     column.id
  //   )
  // ) {
  //   return <span></span>;
};
