import React, { useState, useEffect } from 'react';
import { StyledSearchBox } from './SearchInputComponent.styles';
import { useHistory } from 'react-router-dom';
import { useSearchBox } from 'react-instantsearch';


interface CustomSearchBoxProps {
  id?: string;
  autoFocus?: boolean;
  placeholder?: string;
  initialQuery: string;
}

const CustomSearchBox: React.FC<CustomSearchBoxProps> = ({
  id,
  autoFocus,
  placeholder,
  initialQuery,
}) => {
  const searchBox = useSearchBox();
  const [initialSearch, setInitialSearch] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (!initialSearch && initialQuery) {
      setInitialSearch(true);
      searchBox.query = initialQuery
      searchBox.refine(initialQuery);
    }
  }, [initialQuery, initialSearch, searchBox]);

   useEffect(()=> {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchBox.query) {
      searchParams.set('q', searchBox.query);
    } else {
      searchParams.delete('q');
    }
    const newPath = `/${searchParams.toString() ? `?${searchParams.toString()}` : ''}`;
    history.push(newPath);
  }, [history, searchBox.query])

  return (
    <StyledSearchBox
      autoComplete='off'
      id={id}
      autoFocus={autoFocus}
      placeholder={placeholder}
    />
  );
};

export default CustomSearchBox;
